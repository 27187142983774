import { IS_APP } from '../../react-app/src/constants';
import { MESSAGE_TYPES, sendMessageToReactNative } from './app/rn-messages';
import {
  WEBSOCKETS_MESSAGE_TYPES,
  NewMessagePayload,
  MessageSeenCountsPayload,
} from './user-messages-web-sockets';
import { camelCaseKeys } from './utils';

export const onNewMessage = (payload: NewMessagePayload) => {
  const camelCasedPayload = camelCaseKeys(payload);
  document.dispatchEvent(
    new CustomEvent(WEBSOCKETS_MESSAGE_TYPES.NEW_MESSAGE, { detail: camelCasedPayload })
  );
  if (!IS_APP) return;
  sendMessageToReactNative(MESSAGE_TYPES.MESSAGE_UNSEEN_AND_NEED_REPLY_COUNTS, {
    unseen: camelCasedPayload.unseen,
    needReply: camelCasedPayload.needReply,
  });
};

export const onIsTyping = (payload: MessageSeenCountsPayload) => {
  const camelCasedPayload = camelCaseKeys(payload);
  document.dispatchEvent(
    new CustomEvent(WEBSOCKETS_MESSAGE_TYPES.IS_TYPING, { detail: camelCasedPayload })
  );
};
