// The idea behind moving some `user-messages`-related code into this file
// was:
// 1. To enable the separation of the web-sockets specific code from the
// regular messenger code (by means of putting shared dependencies here).
// This should allow for more architectural cohesion.
// 2. To avoid load order issues
import { convertFile, isHeic } from 'components/ImagesUploader/utils';
import addMessageAlert from './message-alert';

// This is intended to match both the top-level messenger page for
// that username as well as individual threads.
export const currentlyInMessenger = () => window.location.pathname.match(/messages/);

export const isIndividualThreadPage = () =>
  currentlyInMessenger() && window.location.search.includes('thread');

export const currentThreadId = () => new URLSearchParams(window.location.search).get('thread');

export const getMessengerIsLoading = () => (window.MorphMarket || {}).messengerIsLoading;

// The idea behind this function is to enable the admin super-user to
// user the various filters as if they were that user.
export const getCurrentUsername = () => {
  return window.location.pathname.split('/')[2];
};

export const setMessengerIsLoading = (isLoading) => {
  (window.MorphMarket || {}).messengerIsLoading = isLoading;
};

// !!! This returns a JQuery Object. Its empty set will be truthy in JavaScript.
export const getThread = (threadId) => $(`.msg-thread[data-thread-id='${threadId}']`);

export const showToast = (body, isError = false) => {
  const toast = $('.toast-template').clone().removeClass('toast-template d-none');
  toast.find('.toast-body strong').text(body);
  if (isError) {
    toast.addClass('error-toast');
  }

  $('.toast-container').append(toast);
  toast.toast({ delay: 2000 }).toast('show');
};

export const showLoadingDetail = () => {
  setMessengerIsLoading(true);
  $('.btn-actions, .msg-input').prop('disabled', true);

  if ($('.messenger').hasClass('detail-active')) {
    $('.msg-detail-view .spinner-overlay').removeClass('d-none').addClass('d-flex');
  } else {
    $('.msg-list-view .spinner-overlay').removeClass('d-none').addClass('d-flex');
  }
};

export const hideLoadingDetail = () => {
  setMessengerIsLoading(false);
  $('.btn-actions, .msg-input').prop('disabled', false);
  const msgContainer = document.getElementById('msg-container');
  if (msgContainer) {
    msgContainer.classList.remove('twoLines', 'threeLines', 'fourLines', 'fiveLines');
    msgContainer.classList.add('oneLine');
  }

  if ($('.messenger').hasClass('detail-active')) {
    $('.msg-detail-view .spinner-overlay').removeClass('d-flex').addClass('d-none');
  } else {
    $('.msg-list-view .spinner-overlay').removeClass('d-flex').addClass('d-none');
  }
};

const clearTextAreaAndAttachments = () => {
  $('.msg-input').val('');
  $('.msg-input').css('height', '');
  $('#attachments').val('');
  $('#attachments').parent().find('.badge').text(null);
};

const base_message =
  " Please check your internet connection. Click the 'x' to close this message and try again or try later.";

const errorMessagesByAction = {
  text_message: `We could not send your message.${base_message}`,
  block: `We could not block this user.${base_message}`,
  archive: `We could not archive this thread.${base_message}`,
  reopen: `We could not reopen this thread.${base_message}`,
  rate: `We could not save your rating.${base_message}`,
};

export const postAction = (url, data = new FormData(), showLoading = true, action) => {
  // Disable footer input and actions, show spinner overlay
  if (showLoading) showLoadingDetail();
  data.append('isAjaxRequest', 1);
  return $.ajax({
    method: 'POST',
    url,
    dataType: 'json',
    processData: false,
    contentType: false,
    headers: { 'X-CSRFToken': window.CSRF_TOKEN },
    data,
    success: (response) => {
      const { messages, location } = response;

      // Add messages to sessionStorage
      if (messages) {
        sessionStorage.setItem(window.DJANGO_MESSAGES_SS_KEY, JSON.stringify(messages));
      }

      if (action === 'text_message') {
        clearTextAreaAndAttachments();
        hideLoadingDetail();
        return;
      }

      // Redirect
      if (location) {
        let redirectLocation = location;
        // Keep filters for this page
        if (
          redirectLocation === '/messages/' ||
          redirectLocation === `/messages/${window.USERNAME}`
        ) {
          redirectLocation = `/messages/${window.USERNAME}${window.location.search}`;
        }

        window.location.replace(redirectLocation);
      }
    },
    error: (_, error) => {
      console.log('`postAction` hit its error handler with:', error);

      // Some actions that happen via the system should not bubble up issues
      // to user-space, lest they confuse the end-user.
      const ignoredActions = ['mark-seen'];
      if (ignoredActions.includes(action)) {
        console.debug('`postAction` hit its error handler with:', error);
        return;
      }

      let content = 'Something went wrong';
      if (action && errorMessagesByAction[action]) {
        content = errorMessagesByAction[action];
      }
      addMessageAlert({ type: 'danger', content });
      if (showLoading) hideLoadingDetail();
    },
  });
};

export const sendThreadAction = (action, thread, showLoading = true) =>
  (async () => {
    if (!thread) return;
    // The `thread` argument to this function can be a jQuery object which is "truthy" even
    // when being the empty set.
    if (!thread.length) return;

    const { threadId, inquiryId, otherUsername, username } = thread.data();

    // The threadId will be falsy if this is new conversation. The actual value is
    // empty string, due to how it is passed to the data attributes via the Django template.
    const isNewConversation = !threadId;

    // We need to set a default URL here because there are multiple actions,
    // e.g. `already_responded`, that are not listed in the big `if`/`else`
    // statement below.
    let url = `/messages/new/${threadId}`;

    if (action === 'text_message' && isNewConversation) {
      url = `/messages/start_conversation/`;
    } else if (action === 'reopen') {
      url = `/messages/reopen/${threadId}`;
    } else if (action === 'block') {
      url = `/messages/toggle-block-user/${window.USERNAME}/${otherUsername}/${threadId}`;
    } else if (action === 'archive') {
      url = `/messages/toggle-archive-thread/${window.USERNAME}/${threadId}`;
    } else if (action === 'mark-seen') {
      url = `/messages/mark-thread-seen/${window.USERNAME}/${threadId}`;
    } else if (action === 'rate') {
      window.location = `/ratings/${inquiryId}/create`;
      return;
    }

    const data = new FormData();
    data.append('action', action);

    if (action === 'text_message') {
      let [{ files }] = $('#attachments');

      // Convert from .heic
      files = await Promise.all(
        [...files].filter(Boolean).map(async (file) => {
          if (!(await isHeic(file))) return file;
          return convertFile(file);
        })
      );

      data.append('to_username', otherUsername);
      data.append('from_username', username);

      const maxSize = 1024 * 1024 * 10;
      const totalFilesSize = Array.from(files)
        .map((file) => file.size)
        .reduce((totalSize, fileSize) => totalSize + fileSize, 0);
      if (totalFilesSize > maxSize) {
        showToast('Total size exceeds the maximum limit (10MB)', true);
        return;
      }

      data.append('message', $('.msg-input').val());

      if (files.length) {
        $.each(files, (_, file) => {
          data.append('attachments', file);
        });
      }
    }

    postAction(url, data, showLoading, action);
  })();

export const handleSeenLogic = () => {
  if (!isIndividualThreadPage()) {
    return;
  }

  const threadIdUserIsReading = currentThreadId();
  const thread = getThread(threadIdUserIsReading);
  if (!thread?.length) return;

  const { threadUnseen } = thread.data();

  // In order to reduce load on server, we should only seen mark-seen
  // events if a particular thread has not been seen.
  //
  // Note: This currently has a short-coming that it does not work for
  // threads that were unseen at the time of the page refresh.
  if (!threadUnseen) {
    return;
  }

  // If we show loading in this case, it will never go away.
  const showLoading = false;
  sendThreadAction('mark-seen', thread, showLoading);

  // This changes the visuals in the threadChooserPage
  thread.removeClass('new');
};
