// This file contains tools intended to help with debugging and development.
//
// Built-in JS tools like `typeof` are useless and misleading - e.g. `typeof null` returns `'object'`.
/* eslint-disable-next-line  */
export const betterTypeOf = (value: any): string => {
  // If you'd like to test this code, run something like this in the console to confirm
  // it continues to work as expected:
  //
  // class Foo {}

  // [
  //   null,
  //   undefined,
  //   [],
  //   {},
  //   {foo: 'bar'},
  //   new Error(),
  //   new Foo(),
  //   new File([], 'test'),
  //   File,
  //   new Blob(),
  //   new ArrayBuffer(0),
  //   new Uint8Array(0),
  // ].forEach((value) => {
  //   console.log(`Value ${value} typeof ${typeof value}: betterTypeOf: ${betterTypeOf(value)}`);
  // });

  const hasConstructor = value?.constructor;
  const isFunction = typeof value === 'function';
  const isPlainObject = hasConstructor && value.constructor === Object;

  try {
    // The order of these checks is important
    if (isFunction) {
      if (value?.name) {
        return `${value.name} (class/constructor)`;
      }
      return 'anonymous function';
    } else if (isPlainObject) {
      return `object (plain) with data ${JSON.stringify(value)}`;
    } else if (hasConstructor) {
      return `${value.constructor.name} (instance)`;
    } else {
      return Object.prototype.toString
        .call(value)
        .replace(/^\[object\s+([a-z]+)\]$/i, '$1')
        .toLowerCase();
    }
  } catch (error) {
    return 'unknown (error occurred while trying to determine type)';
  }
};
