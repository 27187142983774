// See the corresponding code in the native app `messageHandler.ts`
//
export const MESSAGE_TYPES = {
  APP_UPDATE_BUTTON_PRESSED: 'APP_UPDATE_BUTTON_PRESSED',
  BACK_BUTTON_PRESSED: 'BACK_BUTTON_PRESSED',
  COPIED_TO_CLIPBOARD: 'COPIED_TO_CLIPBOARD',
  EXPORT_ANIMALS: 'EXPORT_ANIMALS',
  OPEN_QR_CODE_SCANNER: 'OPEN_QR_CODE_SCANNER',
  OPEN_LINK_IN_BROWSER: 'OPEN_LINK_IN_BROWSER',
  SHOW_SHARE_DIALOGUE: 'SHOW_SHARE_DIALOGUE',
  STARTED_HORIZONTALLY_SCROLLING: 'STARTED_HORIZONTALLY_SCROLLING',
  STOPPED_HORIZONTALLY_SCROLLING: 'STOPPED_HORIZONTALLY_SCROLLING',
  TOGGLED_PUSH_NOTIFICATION_PERMISSION: 'TOGGLED_PUSH_NOTIFICATION_PERMISSION',
  USER_IS_LOGGED_IN: 'USER_IS_LOGGED_IN',
  USER_IS_LOGGED_OUT: 'USER_IS_LOGGED_OUT',
  USER_WANTS_TO_LOGIN_WITH_FACEBOOK: 'USER_WANTS_TO_LOGIN_WITH_FACEBOOK',
  USER_WANTS_TO_LOGIN_WITH_GOOGLE: 'USER_WANTS_TO_LOGIN_WITH_GOOGLE',
  HANDLE_APP_RATING_PROMPT: 'HANDLE_APP_RATING_PROMPT',
  SEND_WEBVIEW_NAVIGATION_STACK_TO_RN: 'SEND_WEBVIEW_NAVIGATION_STACK_TO_RN',
  IS_PULL_TO_REFRESH_ENABLED_IN_RN: 'IS_PULL_TO_REFRESH_ENABLED_IN_RN',
  MESSAGE_UNSEEN_AND_NEED_REPLY_COUNTS: 'MESSAGE_UNSEEN_AND_NEED_REPLY_COUNTS',
  SEND_COOKIE_TO_RN: 'SEND_COOKIE_TO_RN',
  IS_BACK_DISABLED_IN_RN: 'IS_BACK_DISABLED_IN_RN',
};

export const MM_APP_RATING_EVENTS = {
  UPDATE_LAST_PROMPTED_AT: 'UPDATE_LAST_PROMPTED_AT',
  USER_CALCULATED_TRAITS: 'USER_CALCULATED_TRAITS',
  USER_LEFT_POSITIVE_FEEDBACK: 'USER_LEFT_POSITIVE_FEEDBACK',
  USER_PURCHASED_RGI_SHEDS: 'USER_PURCHASED_RGI_SHEDS',
  USER_UPDATED_AT_LEAST_N_ANIMALS: 'USER_UPDATED_AT_LEAST_N_ANIMALS',
  USER_WANTS_TO_RATE_APP: 'USER_WANTS_TO_RATE_APP',
};

// The WebView we use in React Native has an `onMessage` handler
// which can receive certain messages.
export const sendMessageToReactNative = (messageType, payloadObject = {}) => {
  if (window.IS_APP && window.ReactNativeWebView) {
    const message = JSON.stringify({ type: messageType, payload: payloadObject });
    window.ReactNativeWebView.postMessage(message);
  } else {
    // eslint-disable-next-line no-console
    console.debug(
      `Attempted to send message ${messageType} to React Native, but it was not available.` +
        `Is this feature React-Native only?`
    );
  }
};
