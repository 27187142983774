import type { DropzoneFile } from 'dropzone';
import { betterTypeOf } from 'services/debugging';

import { IMG_LOAD_TIMEOUT_MS, TO_TYPE, TO_TYPE_EXT } from './constants';

/** We use this to get the true MIME type of a file,
 * since the blob's `type` is just based on the file extension.
 */
const getFileHeader = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      if (!e) reject();
      const bytes = new Uint8Array(e.target?.result as ArrayBufferLike);
      const header = [...bytes].map((b) => b.toString(16).padStart(2, '0')).join(' ');
      resolve(header);
    };

    fileReader.readAsArrayBuffer(new Blob([file]).slice(0, 12));
  });

export const isHeic = async (file: File): Promise<boolean> => {
  if (file.name?.match(/(\.heic|\.heif)$/)) return true;
  try {
    // https://en.wikipedia.org/wiki/List_of_file_signatures
    const heicHeaders = ['66 74 79 70 68 65 69 63', '66 74 79 70 6d'];
    const header = await getFileHeader(file);
    // Offset of 4 bytes
    return !!heicHeaders.find((heicHeader) => header.substring(4 * 3).startsWith(heicHeader));
  } catch {
    return false;
  }
};

interface ConvertFileProps {
  file: File;
  quality?: number;
  toType?: 'image/jpeg' | 'image/png';
  toTypeExt?: '.jpeg' | '.png';
}

export const convertFile = async ({
  file,
  quality,
  toType,
  toTypeExt,
}: ConvertFileProps): Promise<File> => {
  // This is to help us debug a persistent issue in production can be removed once it stops occurring.
  // https://morphmarket.sentry.io/issues/5320932646/?project=6204681&query=is%3Aunresolved+issue.priority%3A%5Bhigh%2C+medium%5D&referrer=issue-stream&statsPeriod=2d&stream_index=1
  if (!file) throw new Error('No file to convert');

  try {
    const module = await import('./fromHeic');
    return await module.default({ file, quality, toType, toTypeExt });
  } catch (e) {
    if ((e as { code: number }).code === 1) {
      // Wrongly mislabeled as .heic
      return file;
    } else {
      throw new Error(
        `Couldn't convert image: ${file?.name || 'name unknown'}, size: ${
          file?.size || 'size unknown'
        }, file.type: ${file?.type || 'type unknown'} typeof file: ${betterTypeOf(file)}`
      );
    }
  }
};

export type ConvertedFile = DropzoneFile & { converted?: File };
const convertDropzoneFile = async (file: DropzoneFile): Promise<ConvertedFile> => {
  if (!(await isHeic(file))) return file;
  (file as ConvertedFile).converted = await convertFile({ file });
  return file;
};

export type ValidationConfig = {
  ratio?: number;
  tolerance?: number;
  minWidth?: number;
};
export const validateImage = (file: DropzoneFile, config?: ValidationConfig): Promise<void> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const image = new Image();

      image.onload = () => {
        // Ratio
        if (config?.ratio) {
          const imgRatio = image.width / image.height;
          if (
            imgRatio > config.ratio * (1 + (config.tolerance ?? 0)) ||
            imgRatio < config.ratio * (1 - (config.tolerance ?? 0))
          ) {
            reject('Photo does not meet required width-to-height ratio');
          }
        }

        // Min width
        if (config?.minWidth && image.width < config.minWidth) {
          reject(`Photo does not meet required minimum width (${config.minWidth}px)`);
        }

        resolve();
      };

      // Reject if image doesn't load in time
      image.src = fileReader.result as string;
      setTimeout(() => reject("Couldn't validate image"), IMG_LOAD_TIMEOUT_MS);
    };

    void convertDropzoneFile(file).then((convertedFile) => {
      const { converted } = convertedFile;
      if (converted) {
        fileReader.readAsDataURL(converted);
      } else {
        fileReader.readAsDataURL(file);
      }
    });
  });
};

export type SignedFile = ConvertedFile & {
  s3Data?: Record<string, string> & { fields: { key: string } };
  image_url?: string;
  image_variants?: string[];
};
export const signRequest = async (file: SignedFile, csrfToken: string): Promise<SignedFile> => {
  // file.type here can be empty if the browser doesn't support .heic
  const fileName = file.converted ? file.name + TO_TYPE_EXT : file.name;
  const fileType = file.converted ? TO_TYPE : file.type;

  try {
    const response = await fetch(
      `/api/v1/images/s3_presigned_post/animal_image_upload_presigned_post?${new URLSearchParams({
        file_name: fileName,
        file_type: fileType,
      }).toString()}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      }
    );
    const json = await response.json();
    file.s3Data = json.data;
    file.image_url = json.image_url;
    file.image_variants = json.image_variants;

    return file;
  } catch (e) {
    console.error(e);
    throw new Error('Could not get signed URL');
  }
};
