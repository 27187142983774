export interface IWindow {
  CSRF_TOKEN: string;
  CATEGORY_ID: string;
  WEBSITE_VERSION: string;
  MOBILE_APP_VERSION: string;
  GOOGLE_API_KEY: string;
  IS_APP: boolean;
  IS_DEBUG: boolean;
  IS_STAGING: boolean;
  IS_PRODUCTION: boolean;
  OMIT_NAVIGATION_SHELL: boolean;
  ReactNativeWebView: {
    postMessage: (arg0: string) => void;
  };
}

declare global {
  interface Window extends IWindow {}
}

export const { CATEGORY_ID } = window;
export const { WEBSITE_VERSION } = window;
export const { MOBILE_APP_VERSION } = window;
export const { IS_APP } = window;
export const { IS_DEBUG } = window;
export const { IS_STAGING } = window;
export const { IS_PRODUCTION } = window;
export const { GOOGLE_API_KEY } = window;
export const { OMIT_NAVIGATION_SHELL } = window;
