// Possible types: danger, warning, info
const addMessageAlert = ({ type, content }) => {
  const alertsEl = $('.main-alerts');
  if (!alertsEl.length) {
    console.error("Expected the alerts container to exist, but it didn't");
  }

  alertsEl.addClass('has-alerts');

  const alert = $(`<div class="alert alert-${type} alert-dismissible" role="alert"></div>`);
  alert.append(content);
  alert.append('<button type="button" class="close" data-dismiss="alert">&times;</button>');

  alertsEl.prepend(alert);
};

window.addMessageAlert = addMessageAlert;

export default addMessageAlert;
