import Cookies from 'js-cookie';
import { MESSAGE_TYPES, sendMessageToReactNative } from './app/rn-messages';

/**
 * Abstraction of Cookies.set so we can easily switch it to something else in
 * the future if we want.
 * @param {*} key
 * @param {*} value
 * @param {*} expires
 */
export function setCookie(key, value, expires) {
  Cookies.set(key, value, {
    expires,
  });
}

/**
 * Abstraction of Cookies.get
 * @param {*} key
 * @returns
 */
export function getCookie(key) {
  return Cookies.get(key);
}

// Useful for submitting POST requests in JavaScript
export const createHtmlForm = (url, data) => {
  const form = document.createElement('form');
  form.action = url;
  form.method = 'POST';

  for (const [key, value] of Object.entries(data)) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }

  document.body.appendChild(form);

  return form;
};

export const getCurrentCsrfToken = () =>
  document.querySelector('input[name=csrfmiddlewaretoken').value;

// Get a HTML element. Similarish to jQuery, but no dependencies
export function getEl(selector) {
  return document.querySelector(selector);
}

// Get many HTML elements and place in an array. This is useful
// because the usual return value of NodeList is limited.
export function getEls(selector) {
  return Array(...document.querySelectorAll(selector));
}

// Create a HTML element
export function createEl(tagType, options = { text: null, className: null }) {
  const { className, text } = options;
  const el = document.createElement(tagType);
  if (text) {
    el.innerText = text;
  }
  if (className) {
    el.classList.add(className);
  }
  return el;
}

// Do something once the DOM is loaded.
export const domReady = (callback) => {
  // Check if DOM is already available
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    // Call on next available tick
    setTimeout(callback, 1);
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
};

// Attach event handlers to an element along with a callback
//
// attachHandler({
//   selector: '#inquire_button',
//   eventName: 'click',
//   callback: (element) => (doSomething)
// });
export function attachHandler({ selector, eventName, callback }) {
  const elements = Array.from(document.querySelectorAll(selector));

  elements.forEach((element) =>
    element.addEventListener(eventName, (event) => callback(element, event))
  );
}

// Used for copying text to the clipboard.
// - Inspired by https://stackoverflow.com/a/43001673 and https://stackoverflow.com/a/33928558/286286
//
// - Using the second argument, a jQuery selector, this works well with Bootstrap Popover (https://www.w3schools.com/bootstrap/bootstrap_popover.asp)
// For example, you might create a container element with popover stuff to communicate to the user about the copying process - e.g. `<div id="x" data-toggle="popover" data-placement="top" data-content="Copied"/> `
export const copyText = (stringToCopy, $containerClicked = null) => {
  // Do something to indicate the copy succeeded
  const successHandler = () => {
    console.log('copyText success handler');
    if ($containerClicked) {
      setTimeout(() => {
        $containerClicked.popover('hide');
      }, 500);
    }
  };

  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(stringToCopy)
      .then(successHandler)
      .catch((ex) => {
        /* eslint-disable-next-line */
        console.error('Error copying using writeText()', ex);
        copyTextFallback(stringToCopy, successHandler);
      });
    // The following section is used to support Android WebView
  } else {
    try {
      copyTextFallback(stringToCopy, successHandler);
    } catch (error) {
      /* eslint-disable-next-line */
      console.error('Error copying: this environment does not have a clipboard');
      // Here's where you put the fallback code for older browsers.
    }
  }
};

// When the navigator.clipboard is either unavailable or when we run into
// issues such as "DOMException: Write permission denied.", we use this alternative
// method for writing to the clipboard.
//
// Inspired by https://stackoverflow.com/a/33928558/286286
const copyTextFallback = (stringToCopy, successHandler) => {
  if (window.IS_APP) {
    sendMessageToReactNative(MESSAGE_TYPES.COPIED_TO_CLIPBOARD, { text: stringToCopy });
    return;
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.value = stringToCopy;

    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      console.log('Attempting to copy using execCommand("copy") fallback');
      const isSuccessful = document.execCommand('copy'); // Security exception may be thrown by some browsers.
      if (isSuccessful) {
        return successHandler();
      }
      console.error('Clipboard copying fallback failed too');
      return prompt('Copy to clipboard with Cntl+C', stringToCopy);
    } catch (ex) {
      /* eslint-disable-next-line */
      console.error('Error copying using execCommand', ex);
      /* eslint-disable-next-line */
      return prompt('Copy to clipboard with Cntl+C', stringToCopy);
    } finally {
      document.body.removeChild(textarea);
    }
  } else {
    /* eslint-disable-next-line */
    console.error('Error copying: this environment does not have a clipboard');
    // Here's where you put the fallback code for older browsers.
  }
};

export const hide = (el) => {
  if (!el) {
    return;
  }
  el.style.display = 'none';
};

export const show = (el) => {
  if (!el) {
    return;
  }
  el.style.display = '';
};

// Takes a string that might contain characters with special meaning
// within a regex context and ensure that these characters are escaped.
export const sanitizeForRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\//]/g, '');

// Our Django backend likes to use snake_case for variable names, but
// JavaScript wants camelCase. By wrapping all output from the backend
// passes through a function like this, we can ensure that we're always using the
// correct naming convention in all code in each language -- even for variables
// communicating across the language boundary. This prevents teams from
// accidentally introducing inconsistencies by using the wrong naming convention.
export const toCamelCase = (snakeCasedString) =>
  snakeCasedString.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', '')
  );

// Without this, JavaScript would tell us that an Array is an object.
export function isObjectLiteral(object) {
  return Object.prototype.toString.call(object) === '[object Object]';
}

// Check if the object is an array/object (vs. an int or string)
function isContainerObject(object) {
  return typeof object === 'object';
}

function isDateObject(object) {
  return object instanceof Date;
}

// Recursively turn all the keys for an object (and its sub-objects) to camelCase. The main use case is ensuring
// that other naming conventions (e.g. snake_case) do not
// leak into names within the JavaScript code. Typically
// this function will be placed at the boundary between
// the JavaScript code and some other system.
export function camelCaseKeys(obj, preserveUpperCase = false) {
  if (obj == null) {
    return null;
  }

  if (isObjectLiteral(obj)) {
    return Object.keys(obj).reduce((outputObject, key) => {
      const value = obj[key];
      let processedKey;
      if (preserveUpperCase && key === key.toUpperCase()) processedKey = key;
      else processedKey = toCamelCase(key);
      return {
        ...outputObject,
        [processedKey]: isContainerObject(value) ? camelCaseKeys(value, preserveUpperCase) : value,
      };
    }, {});
  } else if (isDateObject(obj)) {
    return obj;
  }
  return obj.map((value) => (isContainerObject(value) ? camelCaseKeys(value, preserveUpperCase) : value));
}

function snakeCase(string) {
  return string
    .split(/(?=[A-Z0-9])/)
    .join('_')
    .toLowerCase();
}

// Recursively turn all the keys for an object (or its sub-objects)
// to snake_case. The main use case is ensuring consistent variable
// naming when speaking to backend APIs
export function snakeCaseKeys(obj) {
  if (obj == null) {
    return null;
  }

  if (isObjectLiteral(obj)) {
    return Object.keys(obj).reduce((outputObject, key) => {
      const value = obj[key];
      return {
        ...outputObject,
        [snakeCase(key)]: isContainerObject(value) ? snakeCaseKeys(value) : value,
      };
    }, {});
  }
  return obj.map((value) => {
    return isContainerObject(value) ? snakeCaseKeys(value) : value;
  });
}

// Convert an ISOString from the backend into a local time-zone
export const convertTimezone = (isoString) => {
  const date = new Date(isoString);
  // Timezone
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  });

  return formatter.format(date);
};

// The `debug_code` is used to tell us what triggered this request in the logs. It should have the format `category__localid_vX` - e.g. `messenger__pull_to_refresh_v2`
// This function will overwrite any `debug_code` already in the URL.
// Additionally it will preserve any other query parameters
export const reloadPageWithAddedDebugCode = (debugCode) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('debug_code', debugCode);
  window.location.href = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString()}`;
};

// Adds a HTTP header with the WEBSITE_VERSION in order to facilitate debugging
export const fetchWithAppVersion = (url, options = {}) => {
  const headers = {
    ...options.headers,
    'X-App-Version': window.WEBSITE_VERSION,
  };
  /* eslint-disable-next-line no-param-reassign */
  delete options.headers;
  return fetch(url, {
    ...options,
    headers,
  });
};

// This allows adds the appVersion token, which is something we should really always be sending.
export const fetchWithCSRFToken = (url, options = {}) => {
  const headers = {
    ...options.headers,
    'X-CSRFToken': window.CSRF_TOKEN,
  };
  /* eslint-disable-next-line no-param-reassign */
  delete options.headers;
  return fetchWithAppVersion(url, { ...options, headers });
};
