import debounce from 'lodash/debounce';

import { IS_APP } from 'constants/misc';
import { MESSAGE_TYPES, sendMessageToReactNative } from './app/rn-messages';
import {
  currentThreadId,
  currentlyInMessenger,
  handleSeenLogic,
  isIndividualThreadPage,
} from './user-messages-utils';
import { camelCaseKeys, fetchWithAppVersion, getEl } from './utils';

export interface NewMessagePayload {
  need_reply: number;
  thread_id: number;
  trigger_mark_seen: boolean;
  unseen: number;
}

export interface IsTypingPayload {
  thread_id: number;
}

export interface MessageSeenCountsPayload {
  need_reply: number;
  unseen: number;
}

export const WEBSOCKETS_MESSAGE_TYPES = {
  IS_TYPING: 'isTyping',
  NEW_MESSAGE: 'newMessage',
  MESSAGE_SEEN_COUNTS: 'messageSeenCounts',
  NEW_AUCTION_BID: 'newAuctionBid',
  MESSENGER_TABS_CLICK: 'messengerTabsClick',
  UNSEEN_COUNTS: 'unseenCounts',
};

interface CustomWindow extends Window {
  USERNAME: string;
}

declare const window: CustomWindow;

// This constant controls:
// - how often we hit the server with isTyping events
// - how long a typing dot-dot-dot indicator shows for on the client-side before
//   going away
// - the amount we debounce onIsTyping in order to avoid double typing
//   indicators from showing
const IS_TYPING_PERIOD_MS = 30 * 1000;

// In general, we use regular JavaScript events to propagate the WebSockets information throughout the JavaScript system.
// This helps us avoid issues around double-loading web-sockets listeners when dependencies are required from other files.
//
// All events should be dispatched (and listened to) on `document`, not on `window`
export const NEW_MESSAGE_RECEIVED_WHILE_IN_MESSENGER_JS_EVENT_NAME =
  'receivedNewMessageWhileInMessenger';
export const MESSAGE_SEEN_COUNTS_CHANGED_JS_EVENT_NAME = 'messageSeenCountsChanged';

export const updateMessageCounters = (unseenThreads: number) => {
  // This part is used to communicate to the React.js event
  document.dispatchEvent(
    // `CustomEvent` is used instead of `Event` because we want to add a payload.
    new CustomEvent(MESSAGE_SEEN_COUNTS_CHANGED_JS_EVENT_NAME, { detail: { unseenThreads } })
  );
  if (!IS_APP) return;
  sendMessageToReactNative(MESSAGE_TYPES.MESSAGE_UNSEEN_AND_NEED_REPLY_COUNTS, {
    unseenThreads,
  });
};

// Used to respond to the WebSockets NEW_MESSAGE event
// This callback takes different actions depending on whether or not the user is:
//
// a. Currently in the messenger (in which case it shows the new message content on page)
// b. Anywhere else in the site (in which case it updates the unread count for the mail icon)
export const depreciated_onNewMessage = (payload: NewMessagePayload) => {
  const { unseenThreads, triggerMarkSeen, threadId } = camelCaseKeys(payload);
  updateMessageCounters(unseenThreads);

  if (!currentlyInMessenger()) {
    return;
  }

  fetchWithAppVersion(`/messages/${window.USERNAME}`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      // This is a custom Content-Type. Search for the same in the Django app
      // to learn more.
      'Content-Type': 'application/html-fragment',
    },
  })
    .then((response) => response.text())
    .then((html) => {
      const el = document.getElementById('message_list');
      if (!el) return;
      el.innerHTML = html;
      // We need to send an event to modify the page, otherwise things such as the
      // click handlers to visit various threads will no longer work
      // after the HTML has been replaced.
      const event = new CustomEvent(NEW_MESSAGE_RECEIVED_WHILE_IN_MESSENGER_JS_EVENT_NAME, {
        detail: { threadId },
      });
      document.dispatchEvent(event);

      const tabIsFocused = !document.hidden;
      const currentlyOnThreadThatWasUpdated =
        isIndividualThreadPage() && threadId === Number(currentThreadId());

      if (triggerMarkSeen && tabIsFocused && currentlyOnThreadThatWasUpdated) {
        handleSeenLogic();
      }
    })
    /* eslint-disable-next-line no-console  */
    .catch(console.error);
};

// A known limitation of this function is that the "..." indicator will disappear
// if the user switches threads.
export const depreciated_onIsTyping = (payload: IsTypingPayload) => {
  // This event is only relevant for updating the "..." indicator in messenger, so
  // we should do nothing if the user is on a different page in the website.
  if (!currentlyInMessenger()) return;

  // We don't want the typing indicator to appear for the wrong thread
  const { threadId } = camelCaseKeys(payload);

  // This part of the function updates the thread-chooser page with the typing indicator
  // ============
  const lastReplyEl = getEl(`[data-thread-id='${threadId}'] #truncated_last_reply`);
  if (!lastReplyEl) return;

  const lastReplyTextToLaterRestore = lastReplyEl.innerText;
  lastReplyEl.innerText = '';
  lastReplyEl.classList.add('animated-dot-dot-dot', 'emphasized');

  setTimeout(() => {
    lastReplyEl.classList.remove('animated-dot-dot-dot', 'emphasized');
    lastReplyEl.innerText = lastReplyTextToLaterRestore;
  }, IS_TYPING_PERIOD_MS);

  if (isIndividualThreadPage()) {
    if (threadId !== Number(currentThreadId())) {
      return;
    }

    const messageContainer = getEl('.msg-detail-container');
    // The visual strategy here is to take an existing chat message from the other party,
    // clone it, and modify the clone to showing the typing indicator. The reason we check
    // for both `.author-them` and `.author-you` is to handle edge cases where just
    // one party has messaged so far.
    const templateMessage =
      messageContainer.querySelector('.author-them') ||
      messageContainer.querySelector('.author-you');

    // Leave out edge case of where there are no previous messages from the other user
    if (!templateMessage) {
      return;
    }

    // Passing `true` to cloneNode tells it to do a deep copy
    const typingMessage = templateMessage.cloneNode(true);
    typingMessage.querySelector('.msg-timestamp').innerText = '';
    const messageText = typingMessage.querySelector('.message-text');

    // Leave out another edge case where there are no messages with `clean_reply`s from the other user.
    // Leaving this out isn't ideal, but it's rare. Aside from that, we're rewriting this in React.
    if (!messageText) {
      return;
    }

    messageText.innerText = '';
    messageText.classList.add('animated-dot-dot-dot');

    messageContainer.appendChild(typingMessage);
    // By default, the typing indicator will not be viewable
    messageContainer.scrollTop = messageContainer.scrollHeight;

    setTimeout(() => typingMessage.remove(), IS_TYPING_PERIOD_MS);
  }
};

// Overall the approach taken here is hybrid web/web-sockets as opposed to
// pure web-sockets. Instead of sending "is-typing" events directly to
// the other party using the web-sockets server, we go through the full Django
// server as a middle-man. This is slower in terms of performance but faster
// to program. Eventually, a true web-sockets approach should be used.
export const setUpListenerToSendTypingEvents = () => {
  // An architectural challenge here is to ensure that the debouncing resets
  // -- but still works -- whenever the user switches between threads
  // (something that doesn't cause a page refresh). The overall approach is
  // to create a cache of debounced functions, index by threadId, with one
  // debounced function for each thread. This cache is populated lazily.
  const createDebouncedFunction = (threadId: string) =>
    debounce(() => fetchWithAppVersion(`/messages/is-typing/${threadId}/`), IS_TYPING_PERIOD_MS, {
      leading: true,
      trailing: false,
      maxWait: 100,
    });

  const debouncedFunctionsByThreadId: { [key: string]: () => void } = {};

  getEl('.msg-input').addEventListener('keydown', () => {
    const threadId: string | null = currentThreadId();
    // This can happen when a conversation is started between two users but not message is sent yet.
    if (!threadId) {
      return;
    }

    if (threadId in debouncedFunctionsByThreadId) {
      debouncedFunctionsByThreadId[threadId]();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      debouncedFunctionsByThreadId[threadId] = createDebouncedFunction(threadId);
      debouncedFunctionsByThreadId[threadId]();
    }
  });
};

export const setUpListenerToMarkSeenWhenTabIsFocusedAgain = () =>
  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      handleSeenLogic();
    }
  });

// Used to respond to the WebSockets MESSAGE_SEEN_COUNTS event
export const onMessageSeenCounts = (payload: MessageSeenCountsPayload) => {
  const { unseenThreads } = camelCaseKeys(payload);
  updateMessageCounters(unseenThreads);
};
