import { EnumsBucket } from 'constants/enums';
import { traits } from 'constants/enums';
import { GRID_VIEW } from 'components/ViewMenu/definitions';

export type CategoryObject = {
  id: number;
  label: string;
  parent_id: null | number;
  value: string;
  is_egg_laying: boolean;
  has_wizard: boolean;
  fine_grained_subcategory: boolean;
  path: string;
  index_page_path: string;
};

export const { regions, countries } = EnumsBucket;
export const categoryMenuChoices = EnumsBucket.category_menu_choices.filter(
  ({ fine_grained_subcategory }) => !fine_grained_subcategory
);
export const supplyCategoryMenuChoices = EnumsBucket.supply_category_menu_choices;
export const extendedCategoryMenuChoices = EnumsBucket.category_menu_choices;
// FIXME: This needs to be renamed. It is not the general categories (that would be `allCategories`). It is closer to category_menu_choices, but that name is also taken.
export const categories = [...categoryMenuChoices, ...supplyCategoryMenuChoices];
// FIXME: This should really be plain old `categories`. Once we resolve the other FIXMEs, we should refactor accordingly.
export const allCategories = EnumsBucket.categories;
export const rootCategory = allCategories?.find((category) => category.parent_id === null);
export const eventTypes = EnumsBucket.event_types;
export const organisationTypes = EnumsBucket.org_types;
export const traitCountingOptions = EnumsBucket.trait_count_types;

export const getTraitById = (category: number, id: number) => {
  const categoryData = traits[category];

  if (categoryData) {
    const categoryTraits = traits[categoryData.trait_category];
    if (categoryTraits && categoryTraits.has_traits) {
      return categoryTraits.traits_with_aliases_list?.[id];
    }
  }

  return null;
};

export const fallbackCurrencySymbol = '$';

export const SOLD_TO_MM_USER = EnumsBucket.sold_to_mm;
export const SOLD_ELSEWHERE = EnumsBucket.sold_elsewhere;

export const fallBackSearchViewPreference = GRID_VIEW;

export const appRatingPromptFrequencyInMonth = window.APP_RATING_PROMPT_FREQUENCY_IN_MONTHS;
