import { EventBusEventName } from '../../react-app/src/enums/event-bus-event-name';

export const snakesEventService = {
  dispatchEventToReloadMe: (): void => {
    document.dispatchEvent(new CustomEvent(EventBusEventName.SHOULD_RELOAD_ME));
  },

  setUpListenerThatListensToReloadMeEvents: (params?: { callback?: () => void }): void => {
    const { callback } = params ?? {};
    document.addEventListener(EventBusEventName.SHOULD_RELOAD_ME, () => {
      callback?.();
    });
  },

  removeListenerThatListensToReloadMeEvents: (params?: { callback?: () => void }): void => {
    const { callback } = params ?? {};
    document.removeEventListener(EventBusEventName.SHOULD_RELOAD_ME, () => callback?.());
  },
};
