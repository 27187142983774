import hasLocalStorageSupport from 'modernizr-esm/feature/storage/localstorage';
import { appRatingPromptFrequencyInMonth } from 'services/constants';
import { WEBSOCKET_MESSAGE_TYPES } from 'services/websocket';
import { MESSAGE_TYPES, MM_APP_RATING_EVENTS, sendMessageToReactNative } from './rn-messages';

const isAppRatingPromptClosedByUserLocalStorageKey = 'isAppRatingPromptClosedByUser';

export const ratingPromptWebSocketRoute = 'mm_app_ratings';

export const TRIGGER_RATING_PROMPT_EVENT = 'triggerRatingPrompt';

export interface TriggerRatingPromptEvent {
  state: boolean;
}

type WebSocketMessageType = keyof typeof WEBSOCKET_MESSAGE_TYPES;

const ratingPromptWebSocketMessageToMMAppRatingEvents = {
  [WEBSOCKET_MESSAGE_TYPES.USER_UPDATED_AT_LEAST_N_ANIMALS]:
    MM_APP_RATING_EVENTS.USER_UPDATED_AT_LEAST_N_ANIMALS,
  [WEBSOCKET_MESSAGE_TYPES.USER_LEFT_POSITIVE_FEEDBACK]:
    MM_APP_RATING_EVENTS.USER_LEFT_POSITIVE_FEEDBACK,
  [WEBSOCKET_MESSAGE_TYPES.USER_PURCHASED_RGI_SHEDS]: MM_APP_RATING_EVENTS.USER_PURCHASED_RGI_SHEDS,
  [WEBSOCKET_MESSAGE_TYPES.USER_CALCULATED_TRAITS]: MM_APP_RATING_EVENTS.USER_CALCULATED_TRAITS,
};

export const triggerRatingPrompt = () => {
  const event = new CustomEvent(TRIGGER_RATING_PROMPT_EVENT);
  document.dispatchEvent(event);
};

export const sendHandleRatingPromptMessageToReactNative = (event: string) => {
  sendMessageToReactNative(MESSAGE_TYPES.HANDLE_APP_RATING_PROMPT, {
    event,
    ratingFrequencyInMonth: appRatingPromptFrequencyInMonth,
  });
};

const canUseLocalStorage = () => {
  if (!hasLocalStorageSupport) {
    console.error('This device does not have localStorage support');
    return false;
  }
  return true;
};

/**
 * This function is used to save the state of the app rating prompt i.e `isLastAppRatingPromptClosedByUser` in local storage if
 * local storage is available. This is helpful when the user navigates to different pages and state is lost due to reasons
 * like page refresh, redirect etc, and user had not already seen the app rating prompt and not closed it,
 * and in that case we want to persist the state to show the app rating prompt in subsequent visits.
 * @param isLastAppRatingPromptClosedByUser
 **/
export const trySaveIsLastAppRatingPromptClosedByUserInLocalStorage = (
  isLastAppRatingPromptClosedByUser: boolean
) => {
  if (!canUseLocalStorage()) return;
  window.localStorage.setItem(
    isAppRatingPromptClosedByUserLocalStorageKey,
    isLastAppRatingPromptClosedByUser.toString()
  );
};

export const tryGetIsLastAppRatingPromptClosedByUserFromLocalStorage = (): boolean | null => {
  if (!canUseLocalStorage()) return null;
  const isLastAppRatingPromptClosedByUser = window.localStorage.getItem(
    isAppRatingPromptClosedByUserLocalStorageKey
  );
  return isLastAppRatingPromptClosedByUser === 'true';
};

export const handleRatingPromptWebSocketMessage = (type: WebSocketMessageType) => {
  const event = ratingPromptWebSocketMessageToMMAppRatingEvents[type];
  if (!event) {
    console.error(
      '[WebSocket] No associated event found in ratingPromptWebSocketMessageToMMAppRatingEvents map for this app rating websocket message type: ',
      type
    );
    return;
  }
  sendHandleRatingPromptMessageToReactNative(event);
};
