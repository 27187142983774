import ReconnectingWebSocket from 'reconnecting-websocket';

import { IS_APP } from 'constants/misc';
import { WEBSOCKET_MESSAGE_TYPES } from 'services/websocket';
import {
  handleRatingPromptWebSocketMessage,
  ratingPromptWebSocketRoute,
} from './app/ratingPromptUtils';
import { onNewAuctionBid } from './auctions-web-sockets';
import { onIsTyping, onNewMessage } from './new-messenger-web-sockets';
import { snakesEventService } from './snakes-event-service';
import {
  depreciated_onIsTyping,
  depreciated_onNewMessage,
  onMessageSeenCounts,
  WEBSOCKETS_MESSAGE_TYPES,
} from './user-messages-web-sockets';
import { domReady, toCamelCase } from './utils';

interface CustomError {
  name: string;
  message: string;
}

interface CustomWindow extends Window {
  MorphMarket?: {
    WebSockets?: Record<string, ReconnectingWebSocket>;
  };
  IS_AUTHENTICATED: boolean;
}

declare const window: CustomWindow;

// Web-sockets will be prevented from running if its protocol security does not match
// that of the web-page it is called from.
const webSocketsProtocol = () => (window.location.protocol.match(/https/) ? 'wss' : 'ws');

// The purpose of this function is to help us avoid creating double WebSockets connections
// which would unnecessarily deplete server resources.
export const getWebSocketConnection = (route: string) => {
  const existingWebSocket = window?.MorphMarket?.WebSockets?.[route];

  // React's Strict Mode will cause hooks to run twice which will mess up
  // any WebSocket connections created inside them.
  // eslint-disable-next-line
  // @ts-ignore
  if (existingWebSocket && !existingWebSocket._closeCalled) {
    return existingWebSocket;
  }

  const webSockets = window?.MorphMarket?.WebSockets ?? {};
  // We need a `ReconnectingWebSocket` due to Heroku 30s timeout issues.
  webSockets[route] = new ReconnectingWebSocket(
    // This should match a route in one of the `routing.py` files
    `${webSocketsProtocol()}://${window.location.host}/web_sockets/${route}/`,
    undefined,
    {
      // This is needed to prevent a potential self-DDOS situation if the BE endpoint
      // goes down and the FE keeps trying to reconnect. This was tested to ensure
      // that the number of attempts starts over after a successful connection.
      maxRetries: 10,
      reconnectionDelayGrowFactor: 1.7,
      debug: false,
    }
  );

  (window.MorphMarket || {}).WebSockets = webSockets;
  return webSockets[route];
};

// We parameterize the `newMessageHandler` because we need to do things differently depending on whether we are within
// the React.js website or the native app, due to each having different navigation code.
export const initializeWebSocket = (route: string): ReconnectingWebSocket => {
  const webSocket = getWebSocketConnection(route);

  /* eslint-disable-next-line no-unused-vars */
  webSocket.onopen = (_event) => {
    /* eslint-disable-next-line no-console */
    console.debug(`[WebSockets] Successfully connected to the '${route}' WebSocket.`);
  };

  /* eslint-disable-next-line no-unused-vars */
  webSocket.onclose = (_event) => {
    /* eslint-disable-next-line no-console */
    console.debug('[WebSockets] connection closed.');
  };

  webSocket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    // console.log(data);
    /* eslint-disable-next-line no-console */
    console.debug('[WebSockets] Incoming web-socket data', data);
    // If the message from ratingPromptWebSocketRoute, handle it separately and return.
    // The idea is to create sub-message handlers for working with certain categories
    // of web-socket messages belonging to cohesive themes.
    if (route === ratingPromptWebSocketRoute) {
      handleRatingPromptWebSocketMessage(toCamelCase(data.type));
      return;
    }

    switch (toCamelCase(data.type)) {
      case WEBSOCKETS_MESSAGE_TYPES.NEW_MESSAGE:
        depreciated_onNewMessage(data.payload);
        onNewMessage(data.payload);
        break;
      case WEBSOCKET_MESSAGE_TYPES.IS_TYPING:
        depreciated_onIsTyping(data.payload);
        onIsTyping(data.payload);
        break;
      case WEBSOCKET_MESSAGE_TYPES.MESSAGE_SEEN_COUNTS:
        onMessageSeenCounts(data.payload);
        break;
      case WEBSOCKET_MESSAGE_TYPES.NEW_AUCTION_BID:
        onNewAuctionBid(data.payload);
        break;
      case WEBSOCKET_MESSAGE_TYPES.SHOULD_RELOAD_ME:
        snakesEventService.dispatchEventToReloadMe();
        break;
      default:
        throw new Error('[WebSockets] Unknown websockets message type!');
    }
  };

  webSocket.onerror = (error) => {
    const err = error as unknown as CustomError;
    /* eslint-disable-next-line no-console */
    console.error(`[WebSockets] encountered an error: ${err.name} ${err.message}`);
    /* eslint-disable-next-line no-console */

    // We intentionally do not close the WebSockets connection onerror because
    // doing so will prevent the ReconnectingWebSockets from reconnecting between
    // server restarts.
  };

  return webSocket;
};

// We cannot avail of the BFCache system if a WebSocket
// connection is open. Therefore we ensure we close it before pushing the JS heap to
// the cache and then restore it as soon as the page is pulled out of the cache.
// This should ensure that WebSockets continue to work as expected.
// See discussion #3110.
export const closeWebSocketsWhenEnteringBFCache = () => {
  window.addEventListener('pagehide', () => {
    // eslint-disable-next-line no-console
    console.debug('[WebSockets] About to close web-sockets because the page is being hidden.');
    for (const webSocket of Object.values(window?.MorphMarket?.WebSockets ?? {})) {
      webSocket.close();
    }
  });

  // Re-open the WebSockets connection once this page is taking out of the cache
  window.addEventListener('pageshow', () => {
    for (const route of Object.keys(window?.MorphMarket?.WebSockets ?? {})) {
      initializeWebSocket(route);
    }
  });
};

// Prevent double-initialization of the code. And be safe about whether the MorphMarket
// global namespace is already created.
if (!(window.MorphMarket || {}).WebSockets) {
  domReady(() => {
    // The messenger web-sockets backend is auth-only.
    if (window.IS_AUTHENTICATED) {
      initializeWebSocket('messenger');
    }
    if (window.IS_AUTHENTICATED && IS_APP) {
      initializeWebSocket(ratingPromptWebSocketRoute);
      initializeWebSocket('snake');
    }
  });

  domReady(closeWebSocketsWhenEnteringBFCache);
}
