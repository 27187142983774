let variant: string;
// This comes from DefinePlugin in webpack.config.js
declare var ENUMS_BUILD_VARIANT: string;

// FIXME: Make it stop complaining about ENUMS_BUILD_VARIANT
if (ENUMS_BUILD_VARIANT == 'development' || !ENUMS_BUILD_VARIANT) {
  variant = 'current';
} else {
  variant = ENUMS_BUILD_VARIANT;
}

import sameInAllEnvironmentsEnums from '../sameInAllEnvironmentsEnums.current.json';

// We only need to use the ENUMS_BUILD_VARIANT for the variesByEnvironmentEnums since
// the other ones (as the name suggests) are the same in all environments
const variesByEnvironmentEnums = await import(`../variesByEnvironmentEnums.${variant}.json`).then(
  (module) => {
    return { ...module };
  }
);

// We combine both sources into a unified bucket of enums
export const EnumsBucket = { ...sameInAllEnvironmentsEnums, ...variesByEnvironmentEnums };
