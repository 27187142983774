export const WEBSOCKET_MESSAGE_TYPES = {
  IS_TYPING: 'isTyping',
  NEW_MESSAGE: 'newMessage',
  MESSAGE_SEEN_COUNTS: 'messageSeenCounts',
  NEW_AUCTION_BID: 'newAuctionBid',
  // Start Rating Prompt Messages
  USER_CALCULATED_TRAITS: 'userCalculatedTraits',
  USER_PURCHASED_RGI_SHEDS: 'userPurchasedRgiSheds',
  USER_UPDATED_AT_LEAST_N_ANIMALS: 'userUpdatedAtLeastNAnimals',
  USER_LEFT_POSITIVE_FEEDBACK: 'userLeftPositiveFeedback',
  // End Rating Prompt Messages
  SHOULD_RELOAD_ME: 'shouldReloadMe',
};
